import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper, Box, Link, Typography, IconButton, Stack } from "@mui/material";
import { DarkMode, LightMode } from "@mui/icons-material/";
import { Design } from "./App";
import AboutBackdrop from "./About";

type HeaderProps = {
  design: Design;
  setDesign: (design: "light" | "dark" | null) => void;
};

export default function Header(props: HeaderProps) {
  const { design, setDesign } = props;
  return (
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
      <Paper
        elevation={16}
        sx={{
          p: 2,
          m: 4,
          display: "inline-block",
          // maxWidth: 160,
          ":hover": { boxShadow: 20 },
        }}
      >
        <Link component={RouterLink} to="/" underline="none" color="inherit">
          <Typography
            align="center"
            variant="h4"
            // color="#e0e722"
            sx={{ textDecoration: "None" }}
          >
            0124816
          </Typography>
        </Link>
      </Paper>

      <Box alignSelf="center">
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => {
            setDesign(design === "dark" ? "light" : "dark");
          }}
          color="inherit"
        >
          {design === "dark" ? <DarkMode /> : <LightMode />}
        </IconButton>
      </Box>

      <AboutBackdrop />
    </Stack>
  );
}
