import { SvgIcon, SvgIconProps } from "@mui/material";

export function GitIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.6 10.59 8.38 4.8l1.69 1.7c-.24.85.15 1.78.93 2.23v5.54c-.6.34-1 .99-1 1.73a2 2 0 0 0 2 2 2 2 0 0 0 2-2c0-.74-.4-1.39-1-1.73V9.41l2.07 2.09c-.07.15-.07.32-.07.5a2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2c-.18 0-.35 0-.5.07L13.93 7.5a1.98 1.98 0 0 0-1.15-2.34c-.43-.16-.88-.2-1.28-.09L9.8 3.38l.79-.78c.78-.79 2.04-.79 2.82 0l7.99 7.99c.79.78.79 2.04 0 2.82l-7.99 7.99c-.78.79-2.04.79-2.82 0L2.6 13.41c-.79-.78-.79-2.04 0-2.82z" />
    </SvgIcon>
  );
}

export function MatrixIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 520 520" >
<path d="M13.7 11.9v496.2h35.7V520H0V0h49.4v11.9H13.7zM166.3 169.2v25.1h.7c6.7-9.6 14.8-17 24.2-22.2 9.4-5.3 20.3-7.9 32.5-7.9 11.7 0 22.4 2.3 32.1 6.8 9.7 4.5 17 12.6 22.1 24 5.5-8.1 13-15.3 22.4-21.5 9.4-6.2 20.6-9.3 33.5-9.3 9.8 0 18.9 1.2 27.3 3.6 8.4 2.4 15.5 6.2 21.5 11.5s10.6 12.1 14 20.6c3.3 8.5 5 18.7 5 30.7v124.1h-50.9V249.6c0-6.2-.2-12.1-.7-17.6-.5-5.5-1.8-10.3-3.9-14.3-2.2-4.1-5.3-7.3-9.5-9.7-4.2-2.4-9.9-3.6-17-3.6-7.2 0-13 1.4-17.4 4.1-4.4 2.8-7.9 6.3-10.4 10.8-2.5 4.4-4.2 9.4-5 15.1-.8 5.6-1.3 11.3-1.3 17v103.3h-50.9v-104c0-5.5-.1-10.9-.4-16.3-.2-5.4-1.3-10.3-3.1-14.9-1.8-4.5-4.8-8.2-9-10.9-4.2-2.7-10.3-4.1-18.5-4.1-2.4 0-5.6.5-9.5 1.6-3.9 1.1-7.8 3.1-11.5 6.1-3.7 3-6.9 7.3-9.5 12.9-2.6 5.6-3.9 13-3.9 22.1v107.6h-50.9V169.2h48zM506.3 508.1V11.9h-35.7V0H520v520h-49.4v-11.9h35.7z"/>
    </SvgIcon>
  );
}

export function SimpleXIcon(props: SvgIconProps) {
  const design = localStorage.getItem("design");
  if (design === "dark")
    return (
      <img
        width="24px"
        height="24px"
        src="logo-symbol-dark.svg"
        alt="simpleX chat dark logo"
      />
    );
  else
    return (
      <img
        width="24px"
        height="24px"
        src="logo-symbol-light.svg"
        alt="simpleX chat light logo"
      />
    );
}

export function SimpleXLogo(props: SvgIconProps) {
  const design = localStorage.getItem("design");
  if (design === "dark")
    return (
      <img
        width="128"
        height="128"
        src="logo-symbol-dark.svg"
        alt="simpleX chat dark logo"
      />
    );
  else
    return (
      <img
        width="128"
        height="128"
        src="logo-symbol-light.svg"
        alt="simpleX chat light logo"
      />
    );
}
