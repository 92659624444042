import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {
  AccessTime,
  MoreTime,
  Today,
  TodayOutlined,
} from "@mui/icons-material";

function BrailleCard(chr: string, fontsize: number) {
  return (
    <Paper elevation={3} sx={{ m: 1 }}>
      <Typography align={"center"} p={"8px 32px"} fontSize={fontsize + "vw"} fontFamily={"monospace"}>
        {chr}
      </Typography>
    </Paper>
  );
}

function strftime(
  milli: boolean = false,
  binary: boolean = false,
  fontsize: number = 13
) {
  let datetime = new Date();
  if (binary) {
    var str =
      String.fromCharCode(0x2800 + datetime.getHours()) +
      String.fromCharCode(0x2800 + datetime.getMinutes()) +
      String.fromCharCode(0x2800 + datetime.getSeconds());

    return (
      <time>
        <Stack direction={"row"}>
          {str.split("").map((item: string) => BrailleCard(item, fontsize))}
        </Stack>
      </time>
    );
  } else {
    var str =
      datetime.getHours().toString().padStart(2, "0") +
      ":" +
      datetime.getMinutes().toString().padStart(2, "0") +
      ":" +
      datetime.getSeconds().toString().padStart(2, "0");
    if (milli) {
      str = str + "." + datetime.getMilliseconds().toString().padEnd(3, "0");
    }
    return (
      <Paper elevation={3}>
        <Typography align={"center"} p={"8px 32px"} fontSize={fontsize + "vw"}>
          <time>{str}</time>
        </Typography>
      </Paper>
    );
  }
}

export function TimeDisplay(
  milli: boolean = false,
  binary: boolean = false,
  fontsize: number = 13
) {
  const [time, setTime] = useState(strftime(milli, binary));

  useEffect(() => {
    const interval = setInterval(
      () => setTime(strftime(milli, binary, fontsize)),
      milli ? 23 : 250
    );
    return () => {
      clearInterval(interval);
    };
  }, [time, milli, binary]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "auto",
        maxWidth: "80vw",
        alignItems: "center",
      }}
    >
      {time}
    </Box>
  );
}

function strfdate() {
  let datetime = new Date();
  var str =
    datetime.getDate() +
    ". " +
    datetime.getMonth() +
    " " +
    datetime.getFullYear();
  str = datetime.toLocaleDateString("default", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZoneName: "short",
  });
  return str;
}

function DateDisplay(showDate: boolean) {
  const [date, setDate] = useState(strfdate());

  useEffect(() => {
    const interval = setInterval(() => setDate(strfdate()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [date]);

  if (showDate === false) {
    return;
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          m: "16px auto",
          maxWidth: "80vw",
          alignItems: "center",
        }}
      >
        <Paper elevation={3}>
          <Typography align={"center"} p={"8px 32px"} fontSize={"6vw"}>
            <time>{date}</time>
          </Typography>
        </Paper>
      </Box>
    );
  }
}

const BackdropSxProps: SxProps = {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.86)",
  backdropFilter: "blur(8px)",
  zIndex: 1,
};
const EmptySxProps: SxProps = {};

function Clock() {
  const [milli, setMilli] = useState(false);
  const [binary, setBinary] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const handleMilliCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBinary(false);
    setMilli(event.target.checked);
  };
  const handleDateCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDate(event.target.checked);
  };
  const handleBinaryCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMilli(false);
    setBinary(event.target.checked);
  };
  const handleBackdropToggle = () => {
    setBackdrop(!backdrop);
  };

  return (
    <Box sx={{}}>
      <FormGroup sx={{ m: "16px auto", alignContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<TodayOutlined />}
              checkedIcon={<Today />}
              checked={showDate}
              onChange={handleDateCheckbox}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <Typography sx={{ fontWeight: showDate ? "bold" : "normal" }}>
              show date
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<AccessTime />}
              checkedIcon={<MoreTime />}
              checked={milli}
              onChange={handleMilliCheckbox}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <Typography sx={{ fontWeight: milli ? "bold" : "normal" }}>
              milliseconds
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<TodayOutlined />}
              checkedIcon={<Today />}
              checked={binary}
              onChange={handleBinaryCheckbox}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <Typography sx={{ fontWeight: binary ? "bold" : "normal" }}>
              binary
            </Typography>
          }
        />
      </FormGroup>
      <Box
        onClick={handleBackdropToggle}
        sx={backdrop ? BackdropSxProps : EmptySxProps}
      >
        {TimeDisplay(milli, binary)}
        {DateDisplay(showDate)}
      </Box>
    </Box>
  );
}

export default Clock;
