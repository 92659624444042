import * as React from "react";
import {
  Box,
  Link,
  Modal,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Info, Email, ExpandMore, LinkedIn } from "@mui/icons-material/";
import { GitIcon, MatrixIcon, SimpleXIcon } from "./CustomIcons";

function AboutAccordion() {
  return (
    <Box alignSelf="center">
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel1a-header">
          <Typography>
            <Email />
             email
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="mailto:julius@0124816.xyz"
            underline="hover"
            color="text.primary"
          >
            julius@0124816.xyz
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel2a-header">
          <LinkedIn /> <Typography>LinkedIn</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="https://www.linkedin.com/in/julius0124816"
            underline="hover"
            color="text.primary"
          >
            www.linkedin.com/in/julius0124816
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel3a-header">
          <GitIcon /> <Typography>git</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="https://git.0124816.xyz/julius"
            underline="hover"
            color="text.primary"
          >
            git.0124816.xyz/julius
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel4a-header">
          <SimpleXIcon sx={{ pr: "4px" }} /> 
          <Typography>SimpleX chat</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link href="https://simplex.chat/contact#/?v=2-7&smp=smp%3A%2F%2FZKe4uxF4Z_aLJJOEsC-Y6hSkXgQS5-oc442JQGkyP8M%3D%40smp17.simplex.im%2FnMw2T8p9EbV4OzgY2uvbrzKy_QG7elMU%23%2F%3Fv%3D1-3%26dh%3DMCowBQYDK2VuAyEAO8yIapf3xzIKRLGQr4lrIxYVP-MMBnYIljgHei8ih0o%253D%26srv%3Dogtwfxyi3h2h5weftjjpjmxclhb5ugufa5rcyrmg7j4xlch7qsr5nuqd.onion">
            <Box maxWidth="300px" sx={{ width: "96vw", m: "0 auto" }}>
              <img
                src="simplex.chat.png"
                alt="simpleX chat address"
                width="300px"
              />
            </Box>
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel3a-header">
          <MatrixIcon /> <Typography>matrix</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="https://matrix.to/#/@julius:matrix.0124816.xyz"
            underline="hover"
            color="text.primary"
          >
          @julius:matrix.0124816.xyz
          </Link>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default function AboutBackdrop() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box alignSelf="center" sx={{ ml: 1 }}>
      <IconButton onClick={handleOpen} color="inherit" sx={{ ml: 1 }}>
        <Info />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <AboutAccordion />
      </Modal>
    </Box>
  );
}
