import React, { useState, useEffect, SyntheticEvent, ReactNode } from "react";

import {
  Box,
  Button,
  IconButton,
  Input,
  Typography,
  Slider,
  InputProps,
  Divider,
  Stack,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import {
  DeleteForever,
  AddBox,
  Casino,
  Download,
  ScreenRotation,
} from "@mui/icons-material";

function generateRandomHexList(length: number): string[] {
  const hexList: string[] = [];
  const hexCharacters = "0123456789ABCDEF";

  for (let i = 0; i < length; i++) {
    let hexValue = "#";

    for (let j = 0; j < 6; j++) {
      const randomIndex = Math.floor(Math.random() * hexCharacters.length);
      hexValue += hexCharacters[randomIndex];
    }

    hexList.push(hexValue);
  }

  return hexList;
}

function SizeSlider(props: {
  changeHandler: (
    event: SyntheticEvent | Event,
    value: number | number[]
  ) => void;
}) {
  const marks = Array(5)
    .fill(null)
    .map((f, i) => ({
      value: i,
      label: i,
    }));
  return (
    <Box>
      <Typography variant="button" mb={0}>
        size
      </Typography>
      <Slider
        id="size_slider"
        aria-label="size_slider"
        defaultValue={3.0}
        valueLabelDisplay="auto"
        step={0.1}
        marks={marks}
        min={0}
        max={4}
        onChangeCommitted={props.changeHandler}
      />
    </Box>
  );
}

function DensitySlider(props: {
  changeHandler: (
    event: SyntheticEvent | Event,
    value: number | number[]
  ) => void;
}) {
  const marks = Array(11)
    .fill(null)
    .map((f, i) => ({
      value: i / 10,
      label: i % 2 === 0 ? `${i / 10}` : "",
    }));
  return (
    <Box>
      <Typography variant="button" mb={0}>
        density
      </Typography>
      <Slider
        id="density_slider"
        aria-label="density_slider"
        defaultValue={0.1}
        valueLabelDisplay="auto"
        step={0.01}
        marks={marks}
        min={0}
        max={1}
        onChangeCommitted={props.changeHandler}
      />
    </Box>
  );
}

function OrientButton(props: {
  changeHandler: (event: SyntheticEvent | Event) => void;
  orient: string;
}) {
  return (
    <Box>
      <IconButton onClick={props.changeHandler} color="primary" size="large">
        <ScreenRotation
          fontSize="inherit"
          sx={{
            transform:
              props.orient === "landscape" ? "rotate(-45deg)" : "rotate(45deg)",
          }}
        />
      </IconButton>
    </Box>
  );
}

const markers = [
  [".", "point"],
  ["b", "blob"],
  ["+", "plus"],
  ["x", "x"],
  ["o", "circle"],
  ["t", "triangle"],
  ["s", "square"],
  ["S", "sqircle"],
  ["e", "elipse"],
  ["*", "star"],
  ["|", "vline"],
  ["-", "hline"],
  ["Y", "tridash"],
  ["P", "plus (filled)"],
];

function Humanize(str: String) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      minWidth: 200,
    },
  },
};

function MultipleSelectCheckmarks(props: {
  changeHandler: (event: SelectChangeEvent<string[]>) => void;
  marker: string[];
}) {
  var rows: ReactNode[] = [];
  markers.forEach((m, _) => {
    rows.push(
      <MenuItem key={m[1]} value={m[0]}>
        <Checkbox checked={props.marker.indexOf(m[0]) > -1} />
        <ListItemText primary={m[1]} secondary={m[0]} />
      </MenuItem>
    );
  });

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="multiple-checkbox">MARKERS</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={props.marker}
          onChange={props.changeHandler}
          input={<OutlinedInput label="MARKERS" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {rows})
        </Select>
      </FormControl>
    </div>
  );
}

interface ColorListProps {
  bgColor: string;
  setbgColor: (value: string) => void;
  colors: string[];
  setColors: (value: string[]) => void;
  onAddColor: () => void;
  onRemoveColor: (index: number) => void;
  onColorChange: (index: number, value: string) => void;
  density: number;
  setDensity: (value: number) => void;
  onDensityChange: (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => void;
  size: number;
  setSize: (value: number) => void;
  onSizeChange: (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => void;
  orient: string;
  setOrient: (value: string) => void;
  onOrientChange: (event: Event | SyntheticEvent<Element, Event>) => void;
  marker: string[];
  setMarker: (value: string[]) => void;
  onMarkerChange: (event: SelectChangeEvent<string[]>) => void;
}

const ColorList: React.FC<ColorListProps> = ({
  bgColor,
  setbgColor,
  colors,
  setColors,
  onAddColor,
  onRemoveColor,
  onColorChange,
  density,
  setDensity,
  onDensityChange,
  size,
  setSize,
  onSizeChange,
  orient,
  setOrient,
  onOrientChange,
  marker,
  setMarker,
  onMarkerChange,
}) => {
  return (
    <Stack direction="column" spacing={2} useFlexGap flexWrap="wrap">
      <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
        <Box>
          <Typography variant="button" mb={0}>
            background
          </Typography>
          <Box key="bgColorPicker">
            <DeferredInput
              timeout={500}
              type="color"
              value={bgColor}
              onDeferredChange={(value) => setbgColor(value)}
              sx={{ mb: "20px", p: "4px", width: "50px" }}
            />
          </Box>
        </Box>

        <Box alignSelf="center">
          <OrientButton changeHandler={onOrientChange} orient={orient} />
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box alignSelf="center" sx={{ p: "auto" }}>
          <MultipleSelectCheckmarks
            marker={marker}
            changeHandler={onMarkerChange}
          />
        </Box>

        <Box alignSelf="center" sx={{ p: "auto" }}>
          <Button
            variant="text"
            startIcon={<Casino />}
            endIcon={<Casino />}
            sx={{ pr: "20px" }}
            onClick={() => setColors(generateRandomHexList(colors.length))}
          >
            <Typography variant="button" mb={0}>
              random
            </Typography>
          </Button>
          <Typography variant="button" mb={0}>
            speckles
          </Typography>

          <Stack direction="row" useFlexGap flexWrap="wrap">
            {colors.map((color, index) => (
              <div key={index}>
                <DeferredInput
                  timeout={500}
                  type="color"
                  value={color}
                  onDeferredChange={(value) => onColorChange(index, value)}
                  sx={{ p: "4px", width: "50px" }}
                />
                <IconButton
                  onClick={() => onRemoveColor(index)}
                  aria-label="delete"
                  color="error"
                >
                  <DeleteForever />
                </IconButton>
              </div>
            ))}

            <IconButton onClick={onAddColor} color="success">
              <AddBox />
            </IconButton>
          </Stack>
        </Box>
      </Stack>

      <Box alignSelf="center" sx={{ width: "80vw", m: "0 auto" }}>
        <DensitySlider changeHandler={onDensityChange} />
      </Box>

      <Box alignSelf="center" sx={{ width: "80vw", m: "0 auto" }}>
        <SizeSlider changeHandler={onSizeChange} />
      </Box>
    </Stack>
  );
};

function PreviewWindow(
  colors: string[],
  density: number,
  size: number,
  orient: string,
  marker: string[]
) {
  if (marker.length === 0) {
    marker = ["."];
  }
  return (
    <img
      src={
        "https://0124816.xyz/images/speckles/?speckle_colours=" +
        encodeURIComponent(colors.join(",")) +
        "&fileformat=svg" +
        "&density=" +
        String(density) +
        "&size=" +
        String(size) +
        "&orientation=" +
        orient +
        "&markers=" +
        encodeURIComponent(marker.join(""))
      }
      alt="beautiful speckles"
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    />
  );
}

interface DownloadButtonProps {
  colors: string[];
  ext: string;
  density: number;
  size: number;
  orient: string;
  marker: string[];
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  colors,
  ext,
  density,
  size,
  orient,
  marker,
}) => {
  const onDownload = (
    e: React.MouseEvent,
    ext: string,
    density: number,
    size: number
  ) => {
    const link = document.createElement("a");
    link.download = "speckles." + ext;
    link.href =
      "https://0124816.xyz/images/speckles/?speckle_colours=" +
      encodeURIComponent(colors.join(",")) +
      "&fileformat=" +
      ext +
      "&density=" +
      String(density) +
      "&size=" +
      String(size) +
      "&orientation=" +
      orient +
      "&markers=" +
      encodeURIComponent(marker.join(""));
    link.click();
  };

  return (
    <Box alignSelf="center" sx={{ p: "auto", pr: "2vw" }}>
      <Button
        variant="text"
        onClick={(e) => onDownload(e, ext, density, size)}
        endIcon={<Download />}
      >
        <Typography variant="button" mb={0}>
          {ext}
        </Typography>
      </Button>
    </Box>
  );
};

interface DeferredInputProps extends InputProps {
  timeout: number;
  onDeferredChange: (value: string) => void;
}

function DeferredInput(props: DeferredInputProps) {
  const { timeout, onDeferredChange, ...inputProps } = props;
  const [value, setValue] = useState<string | null>(null);
  useEffect(() => {
    const handle = setTimeout(() => {
      setValue(null);
      if (value !== null) onDeferredChange(value);
    }, timeout);
    return () => {
      clearTimeout(handle);
    };
  }, [value, timeout]);
  function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setValue(ev.target.value);
  }
  return (
    <Input {...inputProps} value={value ?? props.value} onChange={onChange} />
  );
}

const App: React.FC = () => {
  const [bgColor, setbgColor] = useState<string>("#ffffff");
  const [colors, setColors] = useState<string[]>(generateRandomHexList(4));
  const [density, setDensity] = useState<number>(0.1);
  const [size, setSize] = useState<number>(3.0);
  const [orient, setOrient] = useState<string>("landscape");
  const [marker, setMarker] = React.useState<string[]>([".", "x", "+"]);

  const addColor = () => {
    setColors([...colors, generateRandomHexList(1)[0]]);
  };

  const removeColor = (index: number) => {
    const updatedColors = [...colors];
    updatedColors.splice(index, 1);
    setColors(updatedColors);
  };

  const handleColorChange = (index: number, value: string) => {
    const updatedColors = [...colors];
    updatedColors[index] = value;
    setColors(updatedColors);
  };

  const handleDensityChange = (
    event: SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    setDensity(newValue as number);
  };

  const handleSizeChange = (
    event: SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    setSize(newValue as number);
  };

  const handleOrientChange = (event: SyntheticEvent | Event) => {
    setOrient(orient === "landscape" ? "portrait" : "landscape");
  };

  const handleMarkerChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setMarker(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(";") : value
    );
  };

  return (
    <Stack direction="column" spacing={2} useFlexGap flexWrap="wrap">
      <Box sx={{ display: "flex", p: "20px" }}>
        <Stack
          alignSelf="center"
          direction="row"
          spacing={2}
          useFlexGap
          flexWrap="wrap"
          sx={{ p: "auto" }}
        >
          <ColorList
            bgColor={bgColor}
            setbgColor={setbgColor}
            colors={colors}
            setColors={setColors}
            onAddColor={addColor}
            onRemoveColor={removeColor}
            onColorChange={handleColorChange}
            density={density}
            setDensity={setDensity}
            onDensityChange={handleDensityChange}
            size={size}
            setSize={setSize}
            onSizeChange={handleSizeChange}
            orient={orient}
            setOrient={setOrient}
            onOrientChange={handleOrientChange}
            marker={marker}
            setMarker={setMarker}
            onMarkerChange={handleMarkerChange}
          />
          <Box
            alignSelf="center"
            sx={{ display: "flex", p: "auto", pl: "2vw", pr: "2vw" }}
          >
            <DownloadButton
              colors={[bgColor, ...colors]}
              ext={"png"}
              density={density}
              size={size}
              orient={orient}
              marker={marker}
            />
            <DownloadButton
              colors={[bgColor, ...colors]}
              ext={"jpg"}
              density={density}
              size={size}
              orient={orient}
              marker={marker}
            />
            <DownloadButton
              colors={[bgColor, ...colors]}
              ext={"svg"}
              density={density}
              size={size}
              orient={orient}
              marker={marker}
            />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          maxWidth: "90vw",
          m: "4px auto 16px",
          borderRadius: 4,
          border: 2.0,
          borderColor: "ghostwhite",
          p: 1.0,
          boxShadow: 8,
        }}
      >
        {PreviewWindow([bgColor, ...colors], density, size, orient, marker)}
      </Box>
    </Stack>
  );
};

export default App;
