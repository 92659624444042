import { useState, SyntheticEvent, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  List,
  ListItemButton,
  Paper,
  Slider,
  Typography,
  Tooltip,
} from "@mui/material";

const XKCD = () => {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 2,
        ":hover": { boxShadow: 12 },
      }}
    >
      <Link href="https://xkcd.com/936/">
        <img
          src="password_strength.png"
          style={{ width: "80vw" }}
          alt="https://xkcd.com/936/"
        />
      </Link>
    </Paper>
  );
};

function CustomSlider(props: {
  label: string;
  changeHandler: (
    event: SyntheticEvent | Event,
    value: number | number[]
  ) => void;
  max: number;
  _default: number;
}) {
  const marks = Array(props.max + 1)
    .fill(null)
    .map((f, i) => ({
      value: i,
      label: i % Math.floor(props.max / 4) === 0 ? `${i}` : "",
    }));

  return (
    <Box sx={{ width: 3 / 4 }}>
      {props.label}
      <Slider
        id={props.label}
        aria-label={props.label}
        defaultValue={props._default}
        valueLabelDisplay="auto"
        step={null}
        marks={marks}
        min={0}
        max={props.max}
        onChangeCommitted={props.changeHandler}
      />
    </Box>
  );
}

const Parameters = () => {
  const [phrases, setPhrases] = useState<string[]>([]);
  const [verbValue, setVerbValue] = useState<number | number[]>(1);
  const [adjValue, setAdjValue] = useState<number | number[]>(1);
  const [nounValue, setNounValue] = useState<number | number[]>(1);
  const [pwValue, setPwValue] = useState<boolean>(false);
  const [entropy, setEntropy] = useState<number>(0);

  useEffect(() => {
    loadPhrases();
  }, [verbValue, nounValue, adjValue, pwValue]);

  async function loadPhrases() {
    const response = await fetch(
      "https://0124816.xyz/api/phrases/" +
        "?n=" +
        20 +
        "&verbs=" +
        verbValue +
        "&adjs=" +
        adjValue +
        "&nouns=" +
        nounValue +
        "&pw=" +
        String(pwValue),
      {
        method: "GET",
      }
    );
    const phrases = await response.json();
    setPhrases(phrases);
    setEntropy(
      Math.log2(
        Math.pow(3856, Number(verbValue)) *
          Math.pow(3725, Number(adjValue)) *
          Math.pow(7866, Number(nounValue)) *
          Math.pow(12000, Number(pwValue))
      )
    );
  }

  function handleClick() {
    loadPhrases();
  }

  const handleVerbChange = (
    event: SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    setVerbValue(newValue as number);
  };

  const handleNounChange = (
    event: SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    setNounValue(newValue as number);
  };

  const handleAdjChange = (
    event: SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    setAdjValue(newValue as number);
  };

  const handlePwChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPwValue(event.target.checked);
  };

  const sliders = [
    {
      label: "verbs",
      changeHandler: handleVerbChange,
      max: 4,
      _default: Number(verbValue),
    },
    {
      label: "adjectives",
      changeHandler: handleAdjChange,
      max: 4,
      _default: Number(adjValue),
    },
    {
      label: "nouns",
      changeHandler: handleNounChange,
      max: 4,
      _default: Number(nounValue),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      minWidth={300}
      alignItems="center"
      justifyContent="center"
      sx={{ width: 3 / 4 }}
    >
      {sliders.map((item) => (
        <CustomSlider
          label={item.label}
          changeHandler={item.changeHandler}
          max={item.max}
          _default={item._default}
        />
      ))}

      <FormGroup>
        <FormControlLabel
          control=<Checkbox id="password" onChange={handlePwChange} />
          label="paranoia mode"
        />
      </FormGroup>

      <Button
        onClick={handleClick}
        color="secondary"
        sx={{ m: 2, maxWidth: 300 }}
      >
        generate
      </Button>

      <List id="phrases" style={{ maxHeight: 400, overflow: "auto" }}>
        {phrases.map((data: string) => {
          return (
            <Tooltip title="click to copy" followCursor>
              <ListItemButton
                key={data}
                dense={true}
                onClick={() => navigator.clipboard.writeText(data)}
              >
                {data}
              </ListItemButton>
            </Tooltip>
          );
        })}
      </List>
      <Box sx={{ p: 4 }}>
        <Typography>
          <strong>disclaimer</strong>: the <b>entropy</b> of password
          combinations with this configuration (and taking the amount of
          different verbs, adjectives and nouns of the underlying database into
          account) works out to <b>≈{entropy.toFixed(2)} bits</b>.
        </Typography>
      </Box>
    </Grid>
  );
};

export default function Phrases() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: 1,
        flexWrap: "wrap",
        gap: 6,
        m: 8,
        p: 4,
      }}
    >
      <Parameters />
      <XKCD />
    </Box>
  );
}
