import { useState, Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import LinkDisplay from "./Links";
import Header from "./Header";
import Phrases from "./Phrases";
import Speckles from "./Speckles";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Clock from "./Time";

const lightTheme = createTheme({
  palette: {
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    grey: {
      "50": "#1a1a1a",
      "100": "#212121",
      "200": "#424242",
      "300": "#616161",
      "400": "#757575",
      "500": "#9e9e9e",
      "600": "#bdbdbd",
      "700": "#e0e0e0",
      "800": "#eeeeee",
      "900": "#f5f5f5",
    },
  },
});

export default function App() {
  const [design, setDesign] = useDesign();
  const theme = design === "dark" ? darkTheme : lightTheme;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Header design={design} setDesign={setDesign} />
        <Suspense>
          <Routes>
            <Route path="/" element={<LinkDisplay />} />
            <Route path="/phrases" element={<Phrases />} />
            <Route path="/speckles" element={<Speckles />} />
            <Route path="/time" element={<Clock />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export type Design = "light" | "dark" | null;
function useDesign(): [Design, (d: Design) => void] {
  const forceUpdate = useForceUpdate();
  const design = localStorage.getItem("design") as Design;
  function setDesign(design: Design) {
    if (design === null) localStorage.removeItem("design");
    else localStorage.setItem("design", design);
    forceUpdate();
  }

  return [design, setDesign];
}

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value +1);
}
